import { useNotification } from 'components/Notification/hooks';
import constants from 'customer/components/emoney/Address/constants';
import { useCallback, useEffect, useState } from 'react';
import { LogSeverity, useLogger } from 'services/logger';
import { Chain } from 'types/emoney/Chain';
import {
  UserRejectedRequestError,
  isErc6492Signature,
  parseErc6492Signature,
} from 'viem';
import {
  useAccount as useWagmiAccount,
  useSignMessage as useWagmiSignMessage,
  useSwitchChain as useWagmiSwitchChain,
} from 'wagmi';

export const useEvmSignMessage = (chain: Chain) => {
  const { logMessage } = useLogger();
  const { notifyError, notifyInfo, notifyWarning } = useNotification();

  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [signature, setSignature] = useState<string | undefined>(undefined);

  const { signMessageAsync: signEvmMessage } = useWagmiSignMessage();
  const { switchChainAsync: switchEvmChain, status } = useWagmiSwitchChain();

  const { address, connector, chainId: connectorChainId } = useWagmiAccount();

  // Reset signature state on mount.
  useEffect(() => {
    setSignature(undefined);
  }, []);

  const parseErc6492 = useCallback(
    (hash: `0x${string}` | string) => {
      if (hash && isErc6492Signature(hash as `0x${string}`)) {
        logMessage({
          message: 'ERC6492 signature detected.',
          severity: 'warning',
        });
        notifyWarning(
          'Pre-deployed signatures are not supported yet. Make sure your smart wallet is fully deployed.',
        );
        hash = parseErc6492Signature(hash as `0x${string}`).signature;
      }

      return hash;
    },
    [signature],
  );

  /**
   * Only Safe off-chain signing works.
   */

  const signMessage = async (message: string) => {
    setError(undefined);
    setIsLoading(true);
    notifyInfo(constants.SIGNATURE_PROMPT);
    try {
      await switchEvmChain({ chainId: Number(chain?.chainId) });
    } catch (e) {
      logMessage({
        message:
          'Error while attempting to switch chain:' + (e as Error)?.message,
        severity: 'error',
        meta: {
          wallet: {
            address: address,
            connectorId: connector?.id,
            connectorType: connector?.type,
            connectorName: connector?.name,
            connectorRdns: connector?.rdns,
            connectorChainId: connectorChainId,
            signingChainId: chain?.chainId,
          },
          chainId: Number(chain?.chainId),
        },
      });
    }
    try {
      const signature = await signEvmMessage({ message: message });

      if (!signature) {
        logMessage({
          message: 'No signature was returned by the wallet.',
          severity: 'error',
        });
        notifyError('No signature was returned by the wallet.');
        setError('No signature was returned by the wallet.');
        throw Error('No signature was returned by the wallet.');
      }

      setSignature(parseErc6492(signature));
      return parseErc6492(signature);
    } catch (e) {
      let err;
      let severity: LogSeverity = 'error';
      if (
        (e as Error)?.message?.toLowerCase().includes('no signature') ||
        (e as Error)?.message?.toLowerCase().includes('request expired')
      ) {
        err = 'No signature was returned by the wallet. Please try again.';
        severity = 'warning';
      } else if (
        e instanceof UserRejectedRequestError ||
        (e as Error)?.message?.toLowerCase().includes('rejected')
      ) {
        err = 'Signature was rejected.';
        severity = 'warning';
      } else if (
        (e as Error)?.message?.toLowerCase().includes('chain not configured')
      ) {
        err = 'Connected chain not supported by Monerium.';
        severity = 'warning';
      } else {
        err = 'Unable to sign with the wallet because of unknown error.';
        severity = 'error';
      }
      logMessage({
        message: 'Error while signing (EVM): ' + (e as Error)?.message,
        severity: severity,
        meta: {
          wallet: {
            address: address,
            connectorId: connector?.id,
            connectorType: connector?.type,
            connectorName: connector?.name,
            connectorRdns: connector?.rdns,
            connectorChainId: connectorChainId,
            signingChainId: chain?.chainId,
            switchingChainStatus: status,
          },
        },
      });
      notifyError(err || (e as Error)?.message);
      setError(err || (e as Error)?.message);
      return undefined;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    signature: signature,
    signMessage,
    isLoading,
    error,
  };
};

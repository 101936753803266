import AutoSelect from 'customer/components/emoney/Account/AutoSelect/AutoSelect';
import Layout from 'customer/components/Layout';

export default () => ({
  chunks: ['accounts-auto-select'],
  title: 'Accounts',
  protected: true,
  component: (
    <Layout isProtected>
      <AutoSelect />
    </Layout>
  ),
});

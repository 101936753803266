import LoadingStatic from 'components/Loading/Static';
import { useEffect } from 'react';
import history from 'utils/history';
import { useAccountSelect } from './hooks';

const AccountSelect = () => {
  const { account, isSuccess } = useAccountSelect();

  useEffect(() => {
    if (account) {
      history?.push(`/accounts/${account?.id}`);
    } else if (!account && isSuccess) {
      history?.push('/accounts');
    }
  }, [account, isSuccess]);

  return <LoadingStatic dialog="Fetching accounts..." />;
};

export default AccountSelect;

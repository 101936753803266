import chains from 'components/Chain/chains';
import { useLogger } from 'services/logger';
import { Chain, EcosystemChainId, EcosystemId } from 'types/emoney/Chain';
import {
  Connector,
  useAccount as useWagmiAccount,
  useConnect as useWagmiConnect,
  useDisconnect as useWagmiDisconnect,
} from 'wagmi';
import { useCosmosProvider, useCosmosSignMessage } from './cosmos/hooks';
import { useEvmSignMessage } from './evm/hooks';
import { SignatureData } from './types';

export const useDisconnect = (kind?: EcosystemId) => {
  const { disconnect: disconnectCosmos } = useCosmosProvider();
  const { disconnectAsync: disconnectEvm } = useWagmiDisconnect();

  const disconnect = async ({ connector }: { connector?: Connector } = {}) => {
    try {
      if (kind === 'cosmos') {
        // Disconnect from cosmos
        disconnectCosmos();
      } else if (kind === 'evm') {
        // Disconnect from evm
        await disconnectEvm({ connector });
      } else if (!kind) {
        // Disconnect from all
        disconnectCosmos();
        await disconnectEvm({ connector });
      }
    } catch (error) {
      console.error('Failed to disconnect', error);
    }
  };

  return { disconnect };
};

export const useConnect = (kind: EcosystemId) => {
  const { connect: connectCosmos } = useCosmosProvider();
  const { connectAsync: connectEvm, connectors } = useWagmiConnect();

  const connect = async (props?: {
    connector: Connector | string;
    chainId?: EcosystemChainId;
  }) => {
    if (kind === 'cosmos') {
      return connectCosmos();
    } else {
      let connector = props?.connector;
      if (!props?.connector) {
        connector = connectors.find((c) => c.type === 'injected');
      }

      const chainId = props?.chainId ? Number(props.chainId) : undefined;
      if (chainId && isNaN(chainId)) {
        throw new Error('Invalid chain ID provided');
      }

      return connectEvm({
        connector: connector as Connector,
        chainId,
      });
    }
  };
  return { connect };
};

export const useConnected = (
  kind: EcosystemId,
): { address: string; chain: Chain } => {
  const { address: evmAddress, chainId: evmChainId } = useWagmiAccount();
  const { address: cosmosAddress, chainId: cosmosChainId } =
    useCosmosProvider();

  if (kind === 'cosmos') {
    return {
      address: cosmosAddress,
      chain: chains.find((c) => c.chainId === cosmosChainId) as Chain,
    };
  } else {
    return {
      address: evmAddress as string,
      chain: chains.find((c) => c.chainId === evmChainId?.toString()) as Chain,
    };
  }
};

export const useAllConnected = (): {
  address: string;
  chain: Chain;
}[] => {
  const { address: evmAddress, chain: evmChain } = useConnected('evm');
  const { address: cosmosAddress, chain: cosmosChain } = useConnected('cosmos');

  return [
    { address: evmAddress, chain: evmChain },
    { address: cosmosAddress, chain: cosmosChain },
  ];
};

export const useSignMessage = (
  chain: Chain,
): {
  signatureData?: SignatureData;
  signMessage: (message: string) => Promise<SignatureData | undefined>;
  isLoading: boolean;
  error: unknown;
} => {
  const { logMessage } = useLogger();
  const {
    signature: evmSignature,
    signMessage: signEvmMessage,
    error: evmError,
    isLoading: evmIsLoading,
  } = useEvmSignMessage(chain);
  const {
    signatureData: cosmosSignatureData,
    signMessage: signCosmosMessage,
    error: cosmosError,
    isLoading: cosmosIsLoading,
  } = useCosmosSignMessage();

  const signMessage = async (message: string) => {
    if (!chain?.kind) {
      logMessage({
        message: 'Cant sign message. Chain info missing.',
        severity: 'error',
      });
      return;
    }
    if (chain.kind === 'cosmos') {
      return await signCosmosMessage(message);
    } else {
      return {
        signature: await signEvmMessage(message),
      };
    }
  };

  let signatureData;
  if (chain.kind === 'cosmos') {
    if (cosmosSignatureData) {
      signatureData = cosmosSignatureData;
    }
  } else {
    if (evmSignature) {
      signatureData = {
        signature: evmSignature,
      };
    }
  }

  return {
    signatureData: signatureData,
    signMessage,
    isLoading: chain.kind === 'cosmos' ? cosmosIsLoading : evmIsLoading,
    error: chain.kind === 'cosmos' ? cosmosError : evmError,
  };
};
